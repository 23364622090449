import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Loader } from "../../components/ui/Loader";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import Enumerable from "linq";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { FilterMatchMode } from "primereact/api";
import { Horse } from "../../queries/models/horse.model";
import {
  useAddHorseMutation,
  useDeleteHorseMutation,
  useEditHorseMutation,
  useHorsesQuery,
} from "../../queries/horses.query";
import { HorseForm } from "./HorsesForm";
import { useParams } from "react-router-dom";

export function Horses() {
  const queryClient = useQueryClient();
  const horsesQuery = useHorsesQuery();
  const addHorseMutation = useAddHorseMutation();
  const editHorseMutation = useEditHorseMutation();
  const deleteHorseMutation = useDeleteHorseMutation();
  const toast = useToast();
  const [selectedHorse, setSelectedHorse] = useState<Horse>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const params = useParams();
  const queryId = useMemo(
    () => (params?.id ? Number(params.id) : undefined),
    [params]
  );
  useEffect(() => {
    if (queryId) {
      const rider = horsesQuery.data?.find((x) => x.id === queryId);

      if (rider) {
        setSelectedHorse(rider);
        setIsModalOpen(true);
      }
    }
  }, [queryId, horsesQuery.data]);

  const handleGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      const value = e.target.value;
      let _filters = filters;
      _filters.global.value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const handleAddNewHorse = () => {
    setSelectedHorse({
      name: "",
    } as Horse);
  };

  const handleSaveHorse = (data: Horse) => {
    return data?.id ? editExistingHorse(data) : saveNewHorse(data);
  };

  const saveNewHorse = (data: Horse) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedHorse(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Udało się!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error.message,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Horse = {
      id: data.id,
      name: data.name,
      shortName: data.shortName,
      comment: data.comment,
      sizeTypeId: data.sizeTypeId,
    };

    return addHorseMutation.mutateAsync(request, mutateOptions);
  };

  const editExistingHorse = (data: Horse) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedHorse(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Udało się!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error.message,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Horse = {
      id: data.id,
      name: data.name,
      shortName: data.shortName,
      comment: data.comment,
      sizeTypeId: data.sizeTypeId,
    };

    return editHorseMutation.mutateAsync(request, mutateOptions);
  };

  const mobileHorseFormModalProps: CustomModalProps = {
    header: selectedHorse?.id ? selectedHorse.name : "Dodaj nowego konia",
    onClose: () => {
      setSelectedHorse(undefined);
      setIsModalOpen(false);
    },
    isOpen: isModalOpen,
    body: (
      <HorseForm
        horse={selectedHorse ?? ({} as Horse)}
        onSave={handleSaveHorse}
        onCancel={() => {
          setSelectedHorse(undefined);
          setIsModalOpen(false);
        }}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
        isSubmitting={isSubmitting}
      />
    ),
  };

  const handleDeleteHorse = useCallback(() => {
    if (selectedHorse) {
      deleteHorseMutation.mutateAsync(selectedHorse.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          toast.current?.show({
            severity: "success",
            detail: "Udało się!",
          });
          setSelectedHorse(undefined);
          setIsDeleteConfirmationModalOpen(false);
          setIsModalOpen(false);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error.message,
          });
        },
      });
    }
  }, [deleteHorseMutation, queryClient, selectedHorse, toast]);

  const mobileModalConfirmationFormProps: CustomModalProps = {
    header: "Potwierdź",
    body: "Czy chcesz usunąć?",
    height: "160px",
    width: "50%",
    isOpen: isDeleteConfirmationModalOpen,
    confirmation: true,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handleDeleteHorse,
  };

  return (
    <>
      {horsesQuery.isLoading ? (
        <div className="h-full flex align-items-center">
          <Loader type="spinner" />
        </div>
      ) : (
        <div className="h-full">
          {!!mobileHorseFormModalProps && (
            <CustomModal {...mobileHorseFormModalProps} />
          )}
          {!!mobileModalConfirmationFormProps && (
            <CustomModal {...mobileModalConfirmationFormProps} />
          )}
          <div className="p-1 h-3rem">
            <InputText
              className="w-full"
              placeholder="Filtr globalny"
              value={globalFilterValue}
              onChange={handleGlobalFilterChange}
            />
          </div>

          <div style={{ height: "calc(100% - 3rem)" }}>
            <DataTable
              value={horsesQuery.data}
              resizableColumns={true}
              scrollable={true}
              scrollHeight="flex"
              selectionMode="single"
              selection={selectedHorse}
              onSelectionChange={(e) => {
                setSelectedHorse(e.value as Horse);
                setIsModalOpen(true);
              }}
              filters={filters}
              globalFilterFields={["name", "comment"]}
              header={
                <TableHeader
                  header="Konie"
                  showAddButton={true}
                  onClickAdd={() => {
                    handleAddNewHorse();
                    setIsModalOpen(true);
                  }}
                />
              }
            >
              <Column field="name" header="Nazwa" sortable />
              <Column field="sizeType.name" header="Rozmiar" sortable />
              <Column
                field="comment"
                header="Komentarz"
                sortable
                body={(x: any) => (
                  <span style={{ whiteSpace: "break-spaces" }}>
                    {x.comment}
                  </span>
                )}
              />
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
}
