import { BarNavigation } from "../components/navigation/bar-navigation";
import { TopBarNavigation } from "../components/navigation/top-bar-navigation";
import { Outlet } from "react-router-dom";
import { useWindowSize } from "../hooks/use-window-size";
import { useAtom } from "jotai";
import { sideBarAtom, sideBarMoreAtom } from "../atoms/side-bar-atom";
import { classNames } from "primereact/utils";

export function ContentPage() {
  const [sidebarState] = useAtom(sideBarAtom);
  const [sidebarMoreState] = useAtom(sideBarMoreAtom);

  const windowSize = useWindowSize();

  return (
    <div
      style={{
        height: windowSize.lg
          ? "calc(100vh - 50px)"
          : `${windowSize.height - 115}px`,
      }}
      className=" w-full"
    >
      <div className="sticky top-0 z-2">
        <TopBarNavigation />
      </div>
      <div className="flex w-full h-full bg-bluegray-50">
        <div
          style={{
            width: windowSize.lg
              ? sidebarMoreState.expanded
                ? sidebarState.expanded
                  ? "32.75rem"
                  : "11rem"
                : sidebarState.expanded
                ? "15rem"
                : "5.5rem"
              : "",
          }}
          className={classNames(
            "transition-duration-500 animation-duration-500 animation-ease-in-out overflow-hidden "
          )}
        >
          <BarNavigation />
        </div>
        <div
          style={!windowSize.lg ? { height: "calc(100% - 4rem)" } : {}}
          className={classNames(
            "w-full h-full overflow-x-hidden",
            windowSize.lg ? "border-round py-1 pr-1" : ""
          )}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}
