import { TabPanel, TabView } from "primereact/tabview";
import { SkillLevels } from "../SkillLevels/SkillLevelsPage";
import { RideTypesPage } from "../RideTypes/RideTypesPage";
import { SizeTypesPage } from "../SizeTypes/SizeTypesPage";
import { useMemo } from "react";
import { authService } from "../../services/auth.service";

export function VariablesTabs() {
  const isAdmin = useMemo(() => {
    return authService.isAdmin();
  }, []);
  return (
    <>
      <TabView>
        <TabPanel header="Poziomy umiejętności">
          <SkillLevels />
        </TabPanel>
        {isAdmin && (
          <TabPanel header="Typy jazdy">
            <RideTypesPage />
          </TabPanel>
        )}
        {isAdmin && (
          <TabPanel header="Rozmiary">
            <SizeTypesPage />
          </TabPanel>
        )}
      </TabView>
    </>
  );
}
