import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Loader } from "../../components/ui/Loader";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import Enumerable from "linq";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { FilterMatchMode } from "primereact/api";
import {
  useAddRiderMutation,
  useDeleteRiderMutation,
  useEditRiderMutation,
  useRidersQuery,
} from "../../queries/riders.query";
import { Rider } from "../../queries/models/rider.model";
import { RiderForm } from "./RidersForm";
import { useParams } from "react-router-dom";
import { useActiveHorsesAsSelectOptionQuery } from "../../queries/horses.query";
import { useActiveSkillLevelsAsSelectOptionQuery } from "../../queries/skill-levels.query";
import { format } from "date-fns";

export function Riders() {
  const queryClient = useQueryClient();
  const ridersQuery = useRidersQuery();
  const addRiderMutation = useAddRiderMutation();
  const editRiderMutation = useEditRiderMutation();
  const horsesOptionsQuery = useActiveHorsesAsSelectOptionQuery();
  const skillsOptionsQuery = useActiveSkillLevelsAsSelectOptionQuery();
  const deleteRiderMutation = useDeleteRiderMutation();
  const toast = useToast();
  const [selectedRider, setSelectedRider] = useState<Rider>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const params = useParams();
  const queryId = useMemo(
    () => (params?.id ? Number(params.id) : undefined),
    [params]
  );
  useEffect(() => {
    if (queryId) {
      const rider = ridersQuery.data?.find((x) => x.id === queryId);

      if (rider) {
        setSelectedRider(rider);
        setIsModalOpen(true);
      }
    }
  }, [queryId, ridersQuery.data]);

  const tableData = useMemo(() => {
    const result = Enumerable.from(ridersQuery.data ?? []);
    result.forEach((x) => {
      x.credits = x.useCredits ? x.credits : -100;
      x.lastRideDate = x.rides?.length ? x.rides![0].date : undefined;
    });
    return result.toArray();
  }, [ridersQuery.data]);

  const peopleWithCarnetsCount = useMemo(
    () => tableData.filter((x) => x.useCredits).length,
    [tableData]
  );

  const totalCount = useMemo(() => tableData.length, [tableData]);

  const handleGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      const value = e.target.value;
      let _filters = filters;
      _filters.global.value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const handleAddNewRider = () => {
    setSelectedRider({
      name: "",
    } as Rider);
  };

  const handleSaveRider = (data: Rider) => {
    return data?.id ? editExistingRider(data) : saveNewRider(data);
  };

  const saveNewRider = (data: Rider) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedRider(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Udało się!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error.message,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Rider = {
      id: data.id,
      age: data.age,
      credits: data.credits,
      name: data.name,
      shortName: data.shortName,
      phoneNumber: data.phoneNumber,
      comment: data.comment,
      useCredits: data.useCredits,
      sizeTypes: data.sizeTypes,
      isChargeable: data.isChargeable,
    };

    return addRiderMutation.mutateAsync(request, mutateOptions);
  };

  const editExistingRider = (data: Rider) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedRider(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Udało się!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error.message,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Rider = {
      id: data.id,
      age: data.age,
      credits: data.credits,
      name: data.name,
      shortName: data.shortName,
      phoneNumber: data.phoneNumber,
      comment: data.comment,
      skills: data.skills,
      useCredits: data.useCredits,
      sizeTypes: data.sizeTypes,
      isChargeable: data.isChargeable,
    };

    return editRiderMutation.mutateAsync(request, mutateOptions);
  };

  const mobileRiderFormModalProps: CustomModalProps = {
    header: selectedRider?.id ? selectedRider.name : "Dodaj nowego jeźdźca",
    onClose: () => {
      setSelectedRider(undefined);
      setIsModalOpen(false);
    },
    isOpen: isModalOpen,
    body: (
      <RiderForm
        rider={selectedRider ?? ({} as Rider)}
        horseOptions={horsesOptionsQuery.data ?? []}
        skillOptions={skillsOptionsQuery.data ?? []}
        onSave={handleSaveRider}
        onCancel={() => {
          setSelectedRider(undefined);
          setIsModalOpen(false);
        }}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
        isSubmitting={isSubmitting}
      />
    ),
  };

  const handleDeleteRider = useCallback(() => {
    if (selectedRider) {
      deleteRiderMutation.mutateAsync(selectedRider.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          toast.current?.show({
            severity: "success",
            detail: "Udało się!",
          });
          setSelectedRider(undefined);
          setIsDeleteConfirmationModalOpen(false);
          setIsModalOpen(false);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error.message,
          });
        },
      });
    }
  }, [deleteRiderMutation, queryClient, selectedRider, toast]);

  const mobileModalConfirmationFormProps: CustomModalProps = {
    header: "Potwierdź",
    body: "Czy chcesz usunąć?",
    height: "160px",
    width: "50%",
    isOpen: isDeleteConfirmationModalOpen,
    confirmation: true,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handleDeleteRider,
  };

  return (
    <>
      {ridersQuery.isLoading ? (
        <div className="h-full flex align-items-center">
          <Loader type="spinner" />
        </div>
      ) : (
        <div className="h-full">
          {!!mobileRiderFormModalProps && (
            <CustomModal {...mobileRiderFormModalProps} />
          )}
          {!!mobileModalConfirmationFormProps && (
            <CustomModal {...mobileModalConfirmationFormProps} />
          )}
          <div className="p-1 h-3rem">
            <InputText
              className="w-full"
              placeholder="Filtr globalny"
              value={globalFilterValue}
              onChange={handleGlobalFilterChange}
            />
          </div>

          <div style={{ height: "calc(100% - 3rem)" }}>
            <DataTable
              value={tableData}
              resizableColumns={true}
              scrollable={true}
              scrollHeight="flex"
              selectionMode="single"
              selection={selectedRider}
              onSelectionChange={(e) => {
                setSelectedRider(e.value as Rider);
                setIsModalOpen(true);
              }}
              style={{ width: "100%" }}
              filters={filters}
              globalFilterFields={["name", "comment"]}
              header={
                <TableHeader
                  header={`Jeźdźcy (${peopleWithCarnetsCount}/${totalCount})`}
                  showAddButton={true}
                  onClickAdd={() => {
                    handleAddNewRider();
                    setIsModalOpen(true);
                  }}
                />
              }
            >
              <Column
                field="name"
                header="Nazwa"
                sortable
                style={{
                  width: "150px",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              />
              <Column field="age" header="Wiek" sortable />
              <Column
                field="credits"
                header="Kr."
                sortable
                body={(x) => (x.useCredits ? x.credits : "N/A")}
              />
              <Column
                field="lastRideDate"
                header="Ost."
                sortable
                body={(x) =>
                  x?.rides.length > 0
                    ? format(x.rides[0].date, "dd/MM/yyyy")
                    : "N/A"
                }
              />
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
}
