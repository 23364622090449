import { useMemo } from "react";
import { SideBarNavigationDesktop } from "./side-bar-nav-desktop";
import { BottomBarNavigationMobile } from "./bottom-bar-nav-mobile";
import { useWindowSize } from "../../hooks/use-window-size";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faUserGroup,
  faRectangleList,
  faGaugeHigh,
  faDollarSign,
  faHouseChimneyWindow,
  faGear,
  faCalendar,
  faLanguage,
  faBuildingUser,
  faQrcode,
  faTag,
  faBox,
  faShieldHalved,
  faCalendarCheck,
  faHorse,
  faPeopleGroup,
  faLevelDown,
  faHandshakeSimpleSlash,
  faBarsProgress,
  faCalendarDay,
  faList,
  faSheetPlastic,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { authService } from "../../services/auth.service";

export interface MenuItem {
  to: string;
  label: string;
  icon: IconProp;
}

export function BarNavigation() {
  const isAdmin = useMemo(() => {
    return authService.isAdmin();
  }, []);

  const menuItems = useMemo(() => {
    const items = [];

    items.push({
      to: "plan",
      label: "Plan",
      icon: faCalendarDay,
    });

    if (isAdmin) {
      items.push({
        to: "riders",
        label: "Jezdzcy",
        icon: faPeopleGroup,
      });
    }

    if (isAdmin) {
      items.push({
        to: "horses",
        label: "Konie",
        icon: faHorse,
      });
    }

    items.push({
      to: "variables",
      label: "Zmienne",
      icon: faBarsProgress,
    });

    if (isAdmin) {
      items.push({
        to: "reports",
        label: "Raportowanie",
        icon: faChartLine,
      });
    }

    if (isAdmin) {
      items.push({
        to: "users",
        label: "Users",
        icon: faUserGroup,
      });
    }

    return items;
  }, [isAdmin]);

  return (
    <>
      <BottomBarNavigationMobile menuItems={menuItems} />
    </>
  );
}
