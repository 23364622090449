import { BaseSyntheticEvent, useCallback, useMemo, useState } from "react";
import { Loader } from "../../components/ui/Loader";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import {
  useAddRideMutation,
  useDeleteRideScheduleMutation,
  useDeleteRideInstanceMutation,
  useDeleteRideMutation,
  useEditRideMutation,
  usePayDateMutation,
  useRidesByDateQuery,
} from "../../queries/rides.query";
import { Ride } from "../../queries/models/ride.model";
import { PlanRideForm } from "./PlanRideForm";
import { useActiveHorsesAsSelectOptionQuery } from "../../queries/horses.query";
import { useActiveRidersAsSelectOptionQuery } from "../../queries/riders.query";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { PlanRow } from "./PlanRow";
import { addDays, addHours, set } from "date-fns";
import { useActiveUsersAsSelectOptionQuery } from "../../queries/users.query";
import { Button } from "primereact/button";
import { authService } from "../../services/auth.service";
import { ConfigForm } from "../../components/ui/config-form/ConfigForm";
import { useActiveSkillLevelsAsSelectOptionQuery } from "../../queries/skill-levels.query";
import { useActiveRideTypesAsSelectOptionQuery } from "../../queries/ride-types.query";
import {
  useGetAppSettingsQuery,
  useSetAppSettingsMutation,
} from "../../queries/appsettings.query";

export function Plan() {
  const queryClient = useQueryClient();
  const horsesOptionsQuery = useActiveHorsesAsSelectOptionQuery();
  const ridersOptionsQuery = useActiveRidersAsSelectOptionQuery();
  const usersOptionsQuery = useActiveUsersAsSelectOptionQuery();
  const skillLevelOptionsQuery = useActiveSkillLevelsAsSelectOptionQuery();
  const rideTypeOptionsQuery = useActiveRideTypesAsSelectOptionQuery();
  const appSettingsQuery = useGetAppSettingsQuery();
  const setAppSettingsMutation = useSetAppSettingsMutation();
  const addRideMutation = useAddRideMutation();
  const editRideMutation = useEditRideMutation();
  const payAllDayMutation = usePayDateMutation();
  const deleteRideMutation = useDeleteRideMutation();
  const deleteRideScheduleMutation = useDeleteRideScheduleMutation();
  const deleteRideInstanceMutation = useDeleteRideInstanceMutation();
  const toast = useToast();
  const [selectedDate, setSelectedDate] = useState<Date>(
    new Date(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      12,
      0,
      0,
      0
    )
  );
  const [selectedRide, setSelectedRide] = useState<Ride>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConfigModalOpen, setConfigIsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [
    isDeleteWithScheduleConfirmationModalOpen,
    setIsDeleteWithScheduleConfirmationModalOpen,
  ] = useState<boolean>(false);
  const [payAllConfirmationModalOpen, setPayAllConfirmationModalOpen] =
    useState<boolean>(false);

  const hours = useMemo(() => {
    let result = [];
    for (let i = 6; i <= 22; i++) {
      result.push(i);
    }

    return result;
  }, []);

  const isAdmin = useMemo(() => {
    return authService.isAdmin();
  }, []);

  const ridesQuery = useRidesByDateQuery(selectedDate);

  const visibleRides = useMemo(() => {
    const userId = authService.getLoggedUser()?.id;
    var result =
      isAdmin && !appSettingsQuery.data?.adminAsUser
        ? ridesQuery.data ?? []
        : ridesQuery.data?.filter((ride) => ride.userId === userId) ?? [];

    return result;
  }, [isAdmin, ridesQuery.data, appSettingsQuery.data]);

  const handleAddNewRide = (date: Date) => {
    setSelectedRide({
      id: 0,
      name: "",
      comment: "",
      date: date,
      isPaid: false,
      horseId: undefined,
      riderId: undefined,
      horse: undefined,
      rider: undefined,
      userId: undefined,
      user: undefined,
      hasIssue: false,
      skillLevelId: undefined,
    } as Ride);
    setIsModalOpen(true);
  };

  const handleEditRide = (ride: Ride) => {
    setSelectedRide(ride);
    setIsModalOpen(true);
  };

  const handlePayAllDay = useCallback(() => {
    setPayAllConfirmationModalOpen(true);
  }, []);

  const handlePayAllDayConfirmed = useCallback(() => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        toast.current?.show({
          severity: "success",
          detail: "Udało się!",
        });
        await queryClient.invalidateQueries("rides");
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error.message,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setPayAllConfirmationModalOpen(false);
      },
    };

    return payAllDayMutation.mutateAsync(selectedDate, mutateOptions);
  }, [payAllDayMutation, queryClient, selectedDate, toast]);

  const handleSaveRide = (data: Ride) => {
    return data?.id || data?.scheduledRideId
      ? editExistingRide(data)
      : saveNewRide(data);
  };

  const confirmPayAllDayDialogProps: CustomModalProps = {
    header: "Potwierdź",
    body: "Upewnij się że wszystkie dziejsze jazdy się odbyły. Jeśli tak potwiedz, zostaną opłacone a kredyty odjęte.",
    height: "200px",
    width: "80%",
    isOpen: payAllConfirmationModalOpen,
    confirmation: true,
    onClose: () => setPayAllConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handlePayAllDayConfirmed,
  };

  const saveNewRide = (data: Ride) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedRide(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Udało się!",
        });
        await queryClient.invalidateQueries("rides");
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error.message,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Ride = {
      id: data.id,
      comment: data.comment,
      date: data.date,
      isPaid: data.isPaid,
      horseId: data.horseId === 0 ? undefined : data.horseId,
      riderId: data.riderId,
      userId: data.userId === 0 || !data.userId ? undefined : data.userId,
      isScheduled: data.isScheduled,
      rideTypeId: data.rideTypeId,
      hasIssue: data.hasIssue,
      skillLevelId: data.skillLevelId,
    };

    return addRideMutation.mutateAsync(request, mutateOptions);
  };

  const editExistingRide = (data: Ride) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedRide(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Udało się!",
        });
        await queryClient.invalidateQueries("rides");
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error.message,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: Ride = {
      id: data.id,
      comment: data.comment,
      date: data.date,
      isPaid: data.isPaid,
      horseId: data.horseId === 0 ? undefined : data.horseId,
      riderId: data.riderId,
      userId: data.userId === 0 || !data.userId ? undefined : data.userId,
      rideTypeId: data.rideTypeId,
      isScheduled: data.isScheduled,
      scheduledRideId: data.scheduledRideId,
      hasIssue: data.hasIssue,
      skillLevelId: data.skillLevelId,
    };

    return editRideMutation.mutateAsync(request, mutateOptions);
  };

  const mobileHorseFormModalProps: CustomModalProps = {
    header:
      selectedRide?.id || selectedRide?.scheduledRideId
        ? "Edytuj jazde"
        : "Dodaj nowa jazde",
    onClose: () => {
      setSelectedRide(undefined);
      setIsModalOpen(false);
    },
    isOpen: isModalOpen,
    body: (
      <PlanRideForm
        ride={selectedRide ?? ({} as Ride)}
        onSave={handleSaveRide}
        horseOptions={horsesOptionsQuery.data ?? []}
        riderOptions={ridersOptionsQuery.data ?? []}
        userOptions={usersOptionsQuery.data ?? []}
        skillLevelOptions={skillLevelOptionsQuery.data ?? []}
        rideTypeOptions={rideTypeOptionsQuery.data ?? []}
        onCancel={() => {
          setSelectedRide(undefined);
          setIsModalOpen(false);
        }}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
        onDeleteWithSchedule={() =>
          setIsDeleteWithScheduleConfirmationModalOpen(true)
        }
        isSubmitting={isSubmitting}
      />
    ),
  };

  const userConfigFormModalProps: CustomModalProps = {
    header: "Ustawienia użytkownika",
    onClose: () => {
      setConfigIsModalOpen(false);
    },
    isOpen: isConfigModalOpen,
    body: (
      <ConfigForm
        config={appSettingsQuery.data}
        onSave={(config) => {
          setAppSettingsMutation.mutateAsync(config, {
            onSuccess: async () => {
              await queryClient.invalidateQueries("appsettings");
              toast.current?.show({
                severity: "success",
                detail: "Udało się!",
              });
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: error.message,
              });
            },
          });
          setConfigIsModalOpen(false);
        }}
        onCancel={() => setConfigIsModalOpen(false)}
      />
    ),
  };

  const handleDeleteRide = useCallback(() => {
    if (selectedRide) {
      if (selectedRide.id) {
        deleteRideMutation.mutateAsync(selectedRide.id, {
          onSuccess: async () => {
            await queryClient.invalidateQueries("rides");
            toast.current?.show({
              severity: "success",
              detail: "Udało się!",
            });
            setSelectedRide(undefined);
            setIsDeleteConfirmationModalOpen(false);
            setIsModalOpen(false);
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error.message,
            });
          },
        });
      } else {
        deleteRideInstanceMutation.mutateAsync(selectedRide, {
          onSuccess: async () => {
            await queryClient.invalidateQueries("rides");
            toast.current?.show({
              severity: "success",
              detail: "Udało się!",
            });
            setSelectedRide(undefined);
            setIsDeleteConfirmationModalOpen(false);
            setIsModalOpen(false);
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error.message,
            });
          },
        });
      }
    }
  }, [
    deleteRideMutation,
    deleteRideInstanceMutation,
    queryClient,
    selectedRide,
    toast,
  ]);

  const handleDeleteWithSchedule = useCallback(() => {
    if (selectedRide) {
      if (selectedRide.id) {
        deleteRideMutation.mutateAsync(selectedRide.id, {
          onSuccess: async () => {
            await queryClient.invalidateQueries("rides");
            toast.current?.show({
              severity: "success",
              detail: "Udało się!",
            });
            setSelectedRide(undefined);
            setIsDeleteWithScheduleConfirmationModalOpen(false);
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error.message,
            });
          },
        });
      }

      if (selectedRide.scheduledRideId) {
        deleteRideScheduleMutation.mutateAsync(selectedRide.scheduledRideId!, {
          onSuccess: async () => {
            await queryClient.invalidateQueries("rides");
            setIsDeleteWithScheduleConfirmationModalOpen(false);
            setIsModalOpen(false);
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error.message,
            });
          },
        });
      }
    }
  }, [
    deleteRideMutation,
    deleteRideScheduleMutation,
    queryClient,
    selectedRide,
    toast,
  ]);

  const mobileModalConfirmationFormProps: CustomModalProps = {
    header: "Potwierdź",
    body: "Czy chcesz usunąć?",
    height: "160px",
    width: "50%",
    isOpen: isDeleteConfirmationModalOpen,
    confirmation: true,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handleDeleteRide,
  };

  const mobileModalConfirmationAllFormProps: CustomModalProps = {
    header: "Potwierdź",
    body: "Czy chcesz usunąć jazde i wszystkie kolejne?",
    height: "160px",
    width: "50%",
    isOpen: isDeleteWithScheduleConfirmationModalOpen,
    confirmation: true,
    onClose: () => setIsDeleteWithScheduleConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handleDeleteWithSchedule,
  };

  const handleSelectedDateChange = useCallback((e: CalendarChangeEvent) => {
    setSelectedDate(addHours(new Date(e.value as string), 12));
  }, []);

  return (
    <>
      {ridesQuery.isLoading ? (
        <div className="h-full flex align-items-center">
          <Loader type="spinner" />
        </div>
      ) : (
        <div className="h-full overflow-hidden">
          {!!mobileHorseFormModalProps && (
            <CustomModal {...mobileHorseFormModalProps} />
          )}
          {!!userConfigFormModalProps && (
            <CustomModal {...userConfigFormModalProps} />
          )}
          {!!mobileModalConfirmationFormProps && (
            <CustomModal {...mobileModalConfirmationFormProps} />
          )}
          {!!mobileModalConfirmationAllFormProps && (
            <CustomModal {...mobileModalConfirmationAllFormProps} />
          )}
          {!!confirmPayAllDayDialogProps && (
            <CustomModal {...confirmPayAllDayDialogProps} />
          )}

          <div className="p-2 flex" style={{ height: "50px" }}>
            <div className="w-10">
              <Calendar
                className="w-full"
                locale="pl"
                dateFormat="DD dd MM yy"
                onChange={handleSelectedDateChange}
                value={selectedDate}
              />
            </div>
            <div className="w-2 text-center">
              <Button
                icon="pi pi-wrench"
                rounded
                text
                severity="secondary"
                aria-label="Cancel"
                onClick={() => {
                  setConfigIsModalOpen(true);
                }}
              />
            </div>
          </div>
          <div className="p-2 flex" style={{ height: "50px" }}>
            <div className="w-6 px-2">
              <Button
                className="w-full bg-white text-color-secondary border-gray-800"
                type="button"
                icon="pi pi-arrow-left"
                label="Poprzedni"
                onClick={() => {
                  setSelectedDate(addDays(selectedDate, -1));
                }}
              />
            </div>
            <div className="w-6 px-2">
              <Button
                className="w-full bg-white text-color-secondary border-gray-800"
                type="button"
                icon="pi pi-arrow-right"
                iconPos="right"
                label="Nastepny"
                onClick={() => {
                  setSelectedDate(addDays(selectedDate, 1));
                }}
              />
            </div>
          </div>

          <div
            className="overflow-auto"
            style={{ height: "calc(100% - 100px)" }}
          >
            {hours.map((hour) => (
              <PlanRow
                key={hour}
                onAddNewRide={handleAddNewRide}
                onEditRide={handleEditRide}
                date={new Date(new Date(selectedDate).setHours(hour))}
                rides={visibleRides}
                shortNames={appSettingsQuery.data?.usePlanShortcuts ?? false}
                showInstructor={appSettingsQuery.data?.showPlanUser ?? false}
              />
            ))}

            {isAdmin && (
              <div className="w-full p-2">
                <Button
                  className="settings-button w-full"
                  type="button"
                  icon="pi pi-save"
                  label="Opłać cały dzień"
                  onClick={handlePayAllDay}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
