export const API_ENDPOINTS: Record<string, string> = {
    AUTHENTICATION: "auth",
    APP_SETTINGS: "app-settings",
    USERS: "users",
    HORSES: "horses",
    RIDERS: "riders",
    RIDES: "rides",
    SKILL_LEVELS: "skill-levels",
    RIDE_TYPES: "ride-types",
    SIZE_TYPES: "size-types",
    REPORTING: "reporting",
}