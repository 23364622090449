import { BaseSyntheticEvent, useCallback, useMemo, useState } from "react";
import { Loader } from "../../components/ui/Loader";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useQueryClient } from "react-query";
import Enumerable from "linq";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { FilterMatchMode } from "primereact/api";
import { Ride } from "../../queries/models/ride.model";
import { useToast } from "../../components/ui/toast-context-provider";
import { useRidesQuery } from "../../queries/rides.query";
import { useRidesReportQuery } from "../../queries/reporting.query";
import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  endOfMonth,
  getDate,
  getUnixTime,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useSizeTypesQuery } from "../../queries/size-types.query";

const Period = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
};

export function Reports() {
  const queryClient = useQueryClient();
  const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
  const [toDate, setToDate] = useState(endOfMonth(new Date()));
  const ridesReportQuery = useRidesReportQuery(fromDate, toDate);
  const sizeTypeQuary = useSizeTypesQuery();

  const [selectedRiders, setSelectedRiders] = useState<string[]>([]);
  const [selectedHorses, setSelectedHorses] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedRideTypes, setSelectedRideTypes] = useState<string[]>([]);
  const [selectedHorseSizes, setSelectedHorseSizes] = useState<string[]>([]);
  const [selectedRiderSizes, setSelectedRiderSizes] = useState<string[]>([]);
  const [period, setPeriod] = useState(Period.MONTH);

  const periodOptions = [
    { label: "Dzień", value: Period.DAY },
    { label: "Tydzień", value: Period.WEEK },
    { label: "Miesiąc", value: Period.MONTH },
  ];

  const sizeTypesOptions = useMemo(() => {
    return (
      sizeTypeQuary.data?.map((st) => ({ label: st.name, value: st.name })) ??
      []
    );
  }, [sizeTypeQuary.data]);

  const allRiders = useMemo(() => {
    return (
      Enumerable.from(ridesReportQuery.data ?? [])
        .distinct((r) => r.rider)
        .select((r) => ({ label: r.rider, value: r.rider }))
        .orderBy((r) => r.label)
        .toArray() ?? []
    );
  }, [ridesReportQuery.data]);

  const allHorses = useMemo(() => {
    return (
      Enumerable.from(ridesReportQuery.data ?? [])
        .distinct((r) => r.horse)
        .select((r) => ({ label: r.horse, value: r.horse }))
        .orderBy((r) => r.label)
        .toArray() ?? []
    );
  }, [ridesReportQuery.data]);

  const allUsers = useMemo(() => {
    return (
      Enumerable.from(ridesReportQuery.data ?? [])
        .distinct((r) => r.user)
        .select((r) => ({ label: r.user, value: r.user }))
        .orderBy((r) => r.label)
        .toArray() ?? []
    );
  }, [ridesReportQuery.data]);

  const allRideTypes = useMemo(() => {
    return (
      Enumerable.from(ridesReportQuery.data ?? [])
        .distinct((r) => r.rideType)
        .select((r) => ({ label: r.rideType, value: r.rideType }))
        .orderBy((r) => r.label)
        .toArray() ?? []
    );
  }, [ridesReportQuery.data]);

  const filteredData = useMemo(() => {
    return (
      Enumerable.from(ridesReportQuery.data ?? [])
        .where((r) =>
          selectedRiders.length > 0 ? selectedRiders.includes(r.rider) : true
        )
        .where((r) =>
          selectedHorses.length > 0 ? selectedHorses.includes(r.horse) : true
        )
        .where((r) =>
          selectedUsers.length > 0 ? selectedUsers.includes(r.user) : true
        )
        .where((r) =>
          selectedHorseSizes.length > 0
            ? selectedHorseSizes.includes(r.horseSize)
            : true
        )
        .where((r) =>
          selectedRiderSizes.length > 0
            ? r.riderSizes.some((s) => selectedRiderSizes.includes(s))
            : true
        )
        .where((r) =>
          selectedRideTypes.length > 0
            ? selectedRideTypes.includes(r.rideType)
            : true
        )
        .toArray() ?? []
    );
  }, [
    ridesReportQuery.data,
    selectedRiders,
    selectedHorses,
    selectedUsers,
    selectedHorseSizes,
    selectedRiderSizes,
    selectedRideTypes,
  ]);

  const filteredDataGroupdByDate = useMemo(() => {
    return (
      Enumerable.from(filteredData)
        .groupBy((r) => {
          let periodStart;

          switch (period) {
            case Period.WEEK:
              periodStart = startOfWeek(new Date(r.date), { weekStartsOn: 1 });
              break;
            case Period.MONTH:
              periodStart = startOfMonth(new Date(r.date));
              break;
            case Period.DAY:
            default:
              periodStart = startOfDay(new Date(r.date));
              break;
          }

          return getUnixTime(periodStart) * 1000;
        })
        .orderBy((g) => g.key())
        .select((g) => {
          return {
            date: new Date(g.key()),
            value: g.getSource().length,
            rides: g.getSource(),
            chargeableCount: g.getSource().filter((r) => r.isChargeable).length,
            nonChargeableCount: g.getSource().filter((r) => !r.isChargeable)
              .length,
          };
        })
        .toArray() ?? []
    );
  }, [filteredData, period]);

  const totalChargeableRidesCount = useMemo(() => {
    return Enumerable.from(filteredData).count((r) => r.isChargeable);
  }, [filteredData]);

  const filteredDataGroupdByHorse = useMemo(() => {
    return (
      Enumerable.from(filteredData)
        .groupBy((r) => r.horse)
        .orderBy((g) => g.key())
        .select((g) => {
          return {
            label: g.key(),
            value: g.getSource().length,
            chargeableCount: g.getSource().filter((r) => r.isChargeable).length,
            nonChargeableCount: g.getSource().filter((r) => !r.isChargeable)
              .length,
            rides: g.getSource(),
          };
        })
        .toArray() ?? []
    );
  }, [filteredData]);

  const avgDayCount = useMemo(() => {
    const daysDiff = differenceInDays(toDate, fromDate) + 1;
    return ((filteredData?.length ?? 0) / daysDiff).toFixed(1);
  }, [fromDate, toDate, filteredData]);

  const avgWeekCount = useMemo(() => {
    const daysDiff = differenceInDays(toDate, fromDate) + 1;
    const diff = daysDiff / 7;
    return ((filteredData?.length ?? 0) / diff).toFixed(1);
  }, [fromDate, toDate, filteredData]);

  const avgMonthCount = useMemo(() => {
    const daysDiff = differenceInDays(toDate, fromDate) + 1;
    const diff = daysDiff / 30;
    return ((filteredData?.length ?? 0) / diff).toFixed(1);
  }, [fromDate, toDate, filteredData]);

  const chartOptions = useMemo(() => {
    const ridesDateDataChargeable = filteredDataGroupdByDate.map(
      (z) =>
        [getUnixTime(new Date(z.date)) * 1000, z.chargeableCount] satisfies [
          number,
          number
        ]
    );

    const ridesDateDataNonChargeable = filteredDataGroupdByDate.map(
      (z) =>
        [getUnixTime(new Date(z.date)) * 1000, z.nonChargeableCount] satisfies [
          number,
          number
        ]
    );

    const chargeableSerie = {
      data: ridesDateDataChargeable.map(([time, value]) => [time, value]),
      name: "Płacone jazdy",
      type: "bar",
      stack: "total",
      itemStyle: {
        color: "#303F9F",
      },
      label: {
        show: true,
        position: "top",
      },
      emphasis: {
        focus: "series",
      },
    };

    const nonChargeableSerie = {
      data: ridesDateDataNonChargeable.map(([time, value]) => [time, value]),
      name: "Niepłatne jazdy",
      type: "bar",
      stack: "total",
      itemStyle: {
        color: "#D32F2F",
      },
      label: {
        show: true,
        position: "top",
      },
      emphasis: {
        focus: "series",
      },
    };

    return {
      grid: { top: 18, right: 16, bottom: 64, left: 32 },
      xAxis: {
        type: "time",
        axisLabel: {
          interval: "auto", // Automatically determine interval
          hideOverlap: true, // Hide overlapping labels
        },
      },
      yAxis: {
        type: "value",
      },
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
      ],
      series: [chargeableSerie, nonChargeableSerie],
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
    } as EChartsOption;
  }, [filteredDataGroupdByDate]);

  const horseChartOptions = useMemo(() => {
    const ridesDateDataChargeable = filteredDataGroupdByHorse.map(
      (z) => [z.label, z.chargeableCount] satisfies [string, number]
    );

    const ridesDateDataNonChargeable = filteredDataGroupdByHorse.map(
      (z) => [z.label, z.nonChargeableCount] satisfies [string, number]
    );

    const chargeableSerie = {
      data: ridesDateDataChargeable.map(([_, value]) => value),
      name: "Płatne jazdy",
      type: "bar",
      stack: "total",
      itemStyle: {
        color: "#303F9F",
      },
      label: {
        show: true,
        position: "top",
      },
      emphasis: {
        focus: "series",
      },
    };

    const nonChargeableSerie = {
      data: ridesDateDataNonChargeable.map(([_, value]) => value),
      name: "Niepłatne jazdy",
      type: "bar",
      stack: "total",
      itemStyle: {
        color: "#D32F2F",
      },
      label: {
        show: true,
        position: "top",
      },
      emphasis: {
        focus: "series",
      },
    };

    return {
      grid: { top: 8, right: 16, bottom: 60, left: 32 },
      xAxis: {
        type: "category",
        data: filteredDataGroupdByHorse.map((z) => z.label), // Use horse names as categories
        axisLabel: {
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
      },
      series: [chargeableSerie, nonChargeableSerie],
      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: ["Chargeable Rides", "Non-Chargeable Rides"],
      },
    } as EChartsOption;
  }, [filteredDataGroupdByHorse]);

  return (
    <div className="w-full">
      <div className="flex flex-row m-2 gap-2">
        <Calendar
          className="w-6"
          locale="pl"
          dateFormat="dd MM yy"
          onChange={(x) => setFromDate(x.value as Date)}
          value={fromDate}
        />

        <Calendar
          className="w-6"
          locale="pl"
          dateFormat="dd MM yy"
          onChange={(x) => setToDate(x.value as Date)}
          value={toDate}
        />
      </div>
      <div className="m-2">
        <Dropdown
          value={period}
          onChange={(e) => setPeriod(e.value)}
          options={periodOptions}
          optionLabel="label"
          placeholder="Grupuj po"
          className="w-full md:w-20rem"
        />
      </div>
      <div className="m-2">
        <MultiSelect
          value={selectedHorses}
          onChange={(e) => setSelectedHorses(e.value)}
          options={allHorses}
          optionLabel="label"
          display="chip"
          placeholder="Filtruj konie"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
      </div>
      <div className="m-2">
        <MultiSelect
          value={selectedRiders}
          onChange={(e) => setSelectedRiders(e.value)}
          options={allRiders}
          optionLabel="label"
          display="chip"
          placeholder="Filtruj jeźdźców"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
      </div>
      <div className="m-2">
        <MultiSelect
          value={selectedHorseSizes}
          onChange={(e) => setSelectedHorseSizes(e.value)}
          options={sizeTypesOptions}
          optionLabel="label"
          display="chip"
          placeholder="Filtruj rozmiar konia"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
      </div>
      <div className="m-2">
        <MultiSelect
          value={selectedUsers}
          onChange={(e) => setSelectedUsers(e.value)}
          options={allUsers}
          optionLabel="label"
          display="chip"
          placeholder="Filtruj instruktorów"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
      </div>
      <div className="m-2">
        <MultiSelect
          value={selectedRiderSizes}
          onChange={(e) => setSelectedRiderSizes(e.value)}
          options={sizeTypesOptions}
          optionLabel="label"
          display="chip"
          placeholder="Filtruj wzrost jeźdźców"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
      </div>
      <div className="m-2">
        <MultiSelect
          value={selectedRideTypes}
          onChange={(e) => setSelectedRideTypes(e.value)}
          options={allRideTypes}
          optionLabel="label"
          display="chip"
          placeholder="Filtruj typy jazd"
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
      </div>

      <div>
        <ReactECharts
          className={"w-full h-12rem p-0 m-0"}
          option={horseChartOptions}
          notMerge={true}
          lazyUpdate={true}
        />
      </div>

      <div>
        <ReactECharts
          className={"w-full h-12rem p-0 m-0"}
          option={chartOptions}
          notMerge={true}
          lazyUpdate={true}
        />
      </div>

      <div>
        <table className="table-auto text-xl">
          <tbody>
            <tr>
              <td>Średnia dzień: </td>
              <td className="font-bold pl-4">{avgDayCount}</td>
            </tr>
            <tr>
              <td>Średnia tydzień: </td>
              <td className="font-bold pl-4">{avgWeekCount}</td>
            </tr>
            <tr>
              <td>Średnia miesiąc: </td>
              <td className="font-bold pl-4">{avgMonthCount}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td className="font-bold pl-4">{filteredData.length}</td>
            </tr>
            <tr>
              <td>Total płatnych</td>
              <td className="font-bold pl-4">{totalChargeableRidesCount}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
