import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import { useToast } from "../../components/ui/toast-context-provider";
import FormikInputTextarea from "../../components/ui/formik/FormikInputTextarea";
import { Ride } from "../../queries/models/ride.model";
import { SelectItem } from "primereact/selectitem";
import FormikCalendar from "../../components/ui/formik/FormikCalendar";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { set } from "date-fns";
import { DayOfWeek } from "../../queries/models/enums/day-of-week";
import FormikInputSwitch from "../../components/ui/formik/FormikInputSwitch";
import { skillLevelsService } from "../../services/skill-levels.service";
import { ridesService } from "../../services/rides.service";
import { authService } from "../../services/auth.service";

export interface PlanRideFormProps {
  ride: Ride;
  horseOptions: SelectItem[];
  riderOptions: SelectItem[];
  userOptions: SelectItem[];
  skillLevelOptions: SelectItem[];
  rideTypeOptions: SelectItem[];
  isSubmitting?: boolean;
  onSave: (form: Ride) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
  onDeleteWithSchedule: () => Promise<any> | void;
}

export function PlanRideForm({
  ride,
  horseOptions,
  riderOptions,
  userOptions,
  skillLevelOptions,
  rideTypeOptions,
  onSave,
  onCancel,
  onDelete,
  onDeleteWithSchedule,
}: PlanRideFormProps) {
  const lg = false;
  const toast = useToast();
  const hasWriteAccess = true;
  const navigate = useNavigate();

  const isAdmin = useMemo(() => {
    return authService.isAdmin();
  }, []);

  const initialValues: Ride = {
    id: ride?.id ?? 0,
    comment: ride?.comment ?? "",
    date: ride?.date ?? new Date(),
    riderId: ride?.riderId ?? 0,
    isPaid: ride?.isPaid ?? false,
    horseId: ride?.horseId ?? undefined,
    rider: ride?.rider ?? undefined,
    horse: ride?.horse ?? undefined,
    userId: ride?.userId ?? 0,
    user: ride?.user ?? undefined,
    rideType: ride?.rideType ?? undefined,
    rideTypeId: ride?.rideTypeId ?? undefined,
    scheduledRideId: ride?.scheduledRideId ?? undefined,
    isScheduled: (ride?.scheduledRideId ?? 0) > 0,
    hasIssue: ride?.hasIssue ?? false,
    skillLevelId: ride?.skillLevelId ?? undefined,
  };
  const validationSchema = Yup.object({
    date: Yup.date().required("Wymagane"),
    comment: Yup.string().optional().nullable(),
    hasIssue: Yup.string().optional().nullable(),
    riderId: Yup.number().required("Wymagane").nonNullable(),
    horseId: Yup.number().optional().nullable(),
    userId: Yup.number().optional().nullable(),
    isPaid: Yup.boolean().optional(),
    rideTypeId: Yup.number().required("Wymagane"),
    isScheduled: Yup.boolean().required("Wymagane"),
    skillLevelId: Yup.number().optional().nullable(),
  });

  const onSubmit = useCallback(
    (values: Ride) => {
      return onSave(values);
    },
    [onSave]
  );

  const handleNavigateToRider = useCallback(
    (id: number | undefined) => {
      navigate(`/app/riders/${id}`);
    },
    [navigate]
  );

  const handleNavigateToHorse = useCallback(
    (id: number | undefined) => {
      navigate(`/app/horses/${id}`);
    },
    [navigate]
  );

  const handleNavigateToUser = useCallback(
    (id: number | undefined) => {
      navigate(`/app/users/${id}`);
    },
    [navigate]
  );

  const fetchSkillLevel = useCallback(
    async (
      riderId: number | undefined,
      horseId: number | undefined,
      formik: any
    ) => {
      if (riderId && horseId) {
        const result = await skillLevelsService.getLevelByRiderHorse(
          riderId,
          horseId
        );
        if (result) {
          formik.setFieldValue("skillLevelId", result);
        } else {
          formik.setFieldValue("skillLevelId", undefined);
        }
      }
    },
    []
  );

  const handleRiderChange = useCallback(
    async (
      riderId: number | undefined,
      horseId: number | undefined,
      formik: any
    ) => {
      if (riderId) {
        const resultHorseId = await ridesService.getLastRiderHorse(riderId);

        if (resultHorseId) {
          formik.setFieldValue("horseId", resultHorseId);
        } else {
          formik.setFieldValue("horseId", undefined);
        }

        horseId = resultHorseId;

        const resultUserId = await ridesService.getLastRiderUser(riderId);
        formik.setFieldValue("userId", resultUserId);

        const resultRideTypeId = await ridesService.getLastRiderRideType(
          riderId
        );
        formik.setFieldValue("rideTypeId", resultRideTypeId);
      }

      fetchSkillLevel(riderId, horseId, formik);
    },
    [fetchSkillLevel]
  );

  const handleHorseChange = useCallback(
    async (
      riderId: number | undefined,
      horseId: number | undefined,
      formik: any
    ) => {
      fetchSkillLevel(riderId, horseId, formik);
    },
    [fetchSkillLevel]
  );

  return (
    <div className="p-2 h-full w-full">
      {true && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form
              autoComplete="off"
              className={classNames(
                "flex",
                lg ? "flex-column" : " flex-column-reverse"
              )}
            >
              <EditContentHeader
                header={ride?.id ? ride.date.toString() : "Dodaj jazde"}
                showDeleteButton={
                  isAdmin && (!!ride.id || !!ride.scheduledRideId)
                }
                saveButtonDisabled={!formik.dirty || !formik.isValid}
                onSaveClick={async () => {
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: "Form invalid",
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />

              <div className="formgrid grid">
                <div className="field col-12 md:col-12">
                  <FormikCalendar
                    label="Data jazdy"
                    name="date"
                    validationSchema={validationSchema}
                    isIndependent
                    locale="pl"
                    dateFormat="DD dd MM yy"
                    showTime={true}
                    disabled={true}
                  />
                </div>

                <div className="field col-12 md:col-12">
                  <div className="flex">
                    <div className="col-12 p-0 m-0">
                      <FormikDropDown
                        label="Typ jazdy"
                        name="rideTypeId"
                        validationSchema={validationSchema}
                        isIndependent
                        options={rideTypeOptions}
                        disabled={!isAdmin}
                      />
                    </div>
                  </div>
                </div>

                <div className="field col-12 md:col-12">
                  <div className="flex">
                    <div className="col-10 p-0 m-0">
                      <FormikDropDown
                        label="Instruktor"
                        name="userId"
                        validationSchema={validationSchema}
                        isIndependent
                        options={userOptions}
                        disabled={!isAdmin}
                        filter
                      />
                    </div>
                    {isAdmin && formik.values.userId !== undefined && (
                      <div className="w-full align-self-end text-right">
                        <Button
                          className="settings-button"
                          type="button"
                          icon="pi pi-arrow-right"
                          onClick={() =>
                            handleNavigateToUser(formik.values.userId)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="field col-12 md:col-12">
                  <div className="flex">
                    <div className="col-10 p-0 m-0">
                      <FormikDropDown
                        label="Jeździec"
                        name="riderId"
                        validationSchema={validationSchema}
                        isIndependent
                        options={riderOptions}
                        disabled={!isAdmin}
                        afterChange={(e) =>
                          handleRiderChange(e, formik.values.horseId, formik)
                        }
                        filter
                      />
                    </div>
                    {isAdmin && formik.values.riderId !== undefined && (
                      <div className="w-full align-self-end text-right">
                        <Button
                          className="settings-button"
                          type="button"
                          icon="pi pi-arrow-right"
                          onClick={() =>
                            handleNavigateToRider(formik.values.riderId)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="field col-12 md:col-12">
                  <div className="flex">
                    <div className="col-10 p-0 m-0">
                      <FormikDropDown
                        label="Koń"
                        name="horseId"
                        validationSchema={validationSchema}
                        isIndependent
                        options={horseOptions}
                        disabled={!isAdmin}
                        afterChange={(e) =>
                          handleHorseChange(formik.values.riderId, e, formik)
                        }
                        filter
                      />
                    </div>
                    {isAdmin && formik.values.horseId !== undefined && (
                      <div className="w-full align-self-end text-right">
                        <Button
                          className="settings-button"
                          type="button"
                          icon="pi pi-arrow-right"
                          onClick={() =>
                            handleNavigateToHorse(formik.values.horseId)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="field col-12 md:col-12">
                  <div className="flex">
                    <div className="col-10 p-0 m-0">
                      <FormikDropDown
                        label="Poziom umiejętności"
                        name="skillLevelId"
                        validationSchema={validationSchema}
                        isIndependent
                        options={skillLevelOptions}
                        disabled={!hasWriteAccess}
                        filter
                      />
                    </div>
                  </div>
                </div>

                <div className="field col-8">
                  <FormikInputTextarea
                    label="Komentarz"
                    name="comment"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-4">
                  <FormikInputSwitch
                    checked={false}
                    label="Problem"
                    name="hasIssue"
                    validationSchema={validationSchema}
                    disabled={!isAdmin}
                  />
                </div>

                <div className="field col-12 md:col-12">
                  <div className="flex">
                    <div className="col-6 p-0 m-0">
                      <FormikInputSwitch
                        checked={false}
                        label="Powtarzaj co tydzień"
                        name="isScheduled"
                        validationSchema={validationSchema}
                        disabled={!isAdmin}
                      />
                    </div>

                    <div className="col-6 p-0 m-0 align-self-center">
                      {ride?.scheduledRideId &&
                        formik.values.isScheduled &&
                        isAdmin && (
                          <Button
                            icon="pi pi-trash"
                            type="button"
                            label="Usuń wszystko"
                            className="delete-button mr-1 w-full"
                            onClick={onDeleteWithSchedule}
                          />
                        )}
                    </div>
                  </div>
                </div>

                {(ride.id > 0 || ride.scheduledRideId) &&
                  !ride.isPaid &&
                  formik.values.rider?.useCredits && (
                    <div className="field col-12 md:col-6 flex justify-content-between">
                      <span className="align-self-center text-xl">
                        {`Pozostałe kredyty: ${formik.values.rider?.credits}`}
                      </span>
                      <Button
                        label="Opłać"
                        onClick={() => {
                          formik.setFieldValue("isPaid", true);
                          formik.submitForm();
                        }}
                      />
                    </div>
                  )}

                {ride.id > 0 && ride.isPaid && (
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <span className="align-self-center text-xl">
                      {`Pozostałe kredyty: ${formik.values.rider?.credits}`}
                    </span>
                    <span className="align-self-center text-xl text-teal-700">
                      {"Jazda opłacona"}
                    </span>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
