import { BaseSyntheticEvent, useCallback, useState } from "react";
import { Loader } from "../../components/ui/Loader";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { FilterMatchMode } from "primereact/api";
import { SizeTypeForm } from "./SizeTypesForm";
import { SizeType } from "../../queries/models/size-type.model";
import {
  useSizeTypesQuery,
  useAddSizeTypeMutation,
  useEditSizeTypeMutation,
  useDeleteSizeTypeMutation,
} from "../../queries/size-types.query";

export function SizeTypesPage() {
  const queryClient = useQueryClient();
  const SizeTypesQuery = useSizeTypesQuery();
  const addSizeTypeMutation = useAddSizeTypeMutation();
  const editSizeTypeMutation = useEditSizeTypeMutation();
  const deleteSizeTypeMutation = useDeleteSizeTypeMutation();
  const toast = useToast();
  const [selectedSizeType, setSelectedSizeType] = useState<SizeType>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const handleGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      const value = e.target.value;
      let _filters = filters;
      _filters.global.value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const handleAddNewSizeType = () => {
    setSelectedSizeType({
      name: "",
    } as SizeType);
  };

  const handleSaveSizeType = (data: SizeType) => {
    return data?.id ? editExistingSizeType(data) : saveNewSizeType(data);
  };

  const saveNewSizeType = (data: SizeType) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedSizeType(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Udało się!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error.message,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: SizeType = {
      id: data.id,
      name: data.name,
      order: data.order,
      comment: data.comment,
    };

    return addSizeTypeMutation.mutateAsync(request, mutateOptions);
  };

  const editExistingSizeType = (data: SizeType) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedSizeType(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Udało się!",
        });
        await queryClient.invalidateQueries();
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error.message,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: SizeType = {
      id: data.id,
      name: data.name,
      order: data.order,
      comment: data.comment,
    };

    return editSizeTypeMutation.mutateAsync(request, mutateOptions);
  };

  const mobileSizeTypeFormModalProps: CustomModalProps = {
    header: selectedSizeType?.id ? selectedSizeType.name : "Dodaj nowy typ",
    onClose: () => {
      setSelectedSizeType(undefined);
      setIsModalOpen(false);
    },
    isOpen: isModalOpen,
    body: (
      <SizeTypeForm
        sizeType={selectedSizeType ?? ({} as SizeType)}
        onSave={handleSaveSizeType}
        onCancel={() => {
          setSelectedSizeType(undefined);
          setIsModalOpen(false);
        }}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
        isSubmitting={isSubmitting}
      />
    ),
  };

  const handleDeleteSizeType = useCallback(() => {
    if (selectedSizeType) {
      deleteSizeTypeMutation.mutateAsync(selectedSizeType.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          toast.current?.show({
            severity: "success",
            detail: "Udało się!",
          });
          setSelectedSizeType(undefined);
          setIsDeleteConfirmationModalOpen(false);
          setIsModalOpen(false);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error.message,
          });
        },
      });
    }
  }, [deleteSizeTypeMutation, queryClient, selectedSizeType, toast]);

  const mobileModalConfirmationFormProps: CustomModalProps = {
    header: "Potwierdź",
    body: "Czy chcesz usunąć?",
    height: "160px",
    width: "50%",
    isOpen: isDeleteConfirmationModalOpen,
    confirmation: true,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handleDeleteSizeType,
  };

  return (
    <>
      {SizeTypesQuery.isLoading ? (
        <div className="h-full flex align-items-center">
          <Loader type="spinner" />
        </div>
      ) : (
        <div className="h-full">
          {!!mobileSizeTypeFormModalProps && (
            <CustomModal {...mobileSizeTypeFormModalProps} />
          )}
          {!!mobileModalConfirmationFormProps && (
            <CustomModal {...mobileModalConfirmationFormProps} />
          )}
          <div className="p-1 h-3rem">
            <InputText
              className="w-full"
              placeholder="Filtr globalny"
              value={globalFilterValue}
              onChange={handleGlobalFilterChange}
            />
          </div>

          <div style={{ height: "calc(100% - 3rem)" }}>
            <DataTable
              value={SizeTypesQuery.data}
              resizableColumns={true}
              scrollable={true}
              scrollHeight="flex"
              selectionMode="single"
              selection={selectedSizeType}
              onSelectionChange={(e) => {
                setSelectedSizeType(e.value as SizeType);
                setIsModalOpen(true);
              }}
              filters={filters}
              globalFilterFields={["name", "comment"]}
              header={
                <TableHeader
                  header="Poziomy umiejętności"
                  showAddButton={true}
                  onClickAdd={() => {
                    handleAddNewSizeType();
                    setIsModalOpen(true);
                  }}
                />
              }
            >
              <Column field="order" header="Kolejność" sortable />
              <Column field="name" header="Nazwa" sortable />
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
}
