import { useCallback, useMemo } from "react";
import { Form, Formik } from "formik";
import { EnumExtensions } from "../../utils/enum-extensions";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import FormikMultiSelect from "../../components/ui/formik/FormikMultiSelect";
import FormikInputNumber from "../../components/ui/formik/FormikInputNumber";
import FormikInputSwitch from "../../components/ui/formik/FormikInputSwitch";
import { useWindowSize } from "../../hooks/use-window-size";
import { useToast } from "../../components/ui/toast-context-provider";
import { confirmDialog } from "primereact/confirmdialog";
import { phoneRegExp } from "../../utils/phoneRegExp";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import { authService } from "../../services/auth.service";
import { Horse } from "../../queries/models/horse.model";
import FormikInputTextarea from "../../components/ui/formik/FormikInputTextarea";
import { useRidesByHorseQuery } from "../../queries/rides.query";
import { FormInputLabel } from "../../components/ui/formik/FormInputLabel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSizeTypesQuery } from "../../queries/size-types.query";

export interface HorseEditFormProps {
  horse: Horse;
  isSubmitting?: boolean;
  onSave: (form: Horse) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
}

export function HorseForm({
  horse,
  onSave,
  onCancel,
  onDelete,
}: HorseEditFormProps) {
  const lg = false;
  const toast = useToast();
  const hasWriteAccess = true;
  const ridesHistoryQuery = useRidesByHorseQuery(horse.id);
  const SizeTypesQuery = useSizeTypesQuery();

  const historyData = useMemo(() => {
    let result = ridesHistoryQuery.data?.map((x) => ({
      date: x.date,
      rider: x.rider?.name ?? "",
      user: x.user?.username ?? "",
      currentCredit: x.currentCredit ?? "",
      color: x.hasIssue ? "text-red-600" : "",
    }));

    return result;
  }, [ridesHistoryQuery.data]);

  const initialValues: Horse = {
    id: horse?.id ?? 0,
    name: horse?.name ?? "",
    comment: horse?.comment ?? "",
    shortName: horse?.shortName ?? "",
    skills: [],
    rides: [],
    sizeTypeId: horse?.sizeTypeId ?? 0,
  };
  const validationSchema = Yup.object({
    name: Yup.string().min(3, "Podaj minimum 3 znaki").required("Required"),
    shortName: Yup.string(),
    comment: Yup.string().optional().nullable(),
    sizeTypeId: Yup.number().optional().nullable(),
  });

  const sizeTypeOptions = useMemo(() => {
    return SizeTypesQuery.data?.map((x) => ({
      label: x.name,
      value: x.id,
    }));
  }, [SizeTypesQuery.data]);

  const onSubmit = useCallback(
    (values: Horse) => {
      return onSave(values);
    },
    [onSave]
  );

  return (
    <div className="p-2 h-full w-full">
      {true && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form
              autoComplete="off"
              className={classNames("flex", "flex-column")}
            >
              <EditContentHeader
                header={horse?.id ? horse.name : "Dodaj nowego konia"}
                showDeleteButton={hasWriteAccess && !!horse.id}
                saveButtonDisabled={
                  !hasWriteAccess || !formik.dirty || !formik.isValid
                }
                onSaveClick={async () => {
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: "Form invalid",
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />

              <div className="formgrid grid">
                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label="Nazwa"
                    name="name"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>
                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label="Skrót"
                    name="shortName"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputTextarea
                    label="Komentarz"
                    name="comment"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikDropDown
                    label="Rozmiar"
                    name="sizeTypeId"
                    options={sizeTypeOptions}
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>
              </div>

              <div className="field col-12 md:col-6 p-0 m-0 mt-4">
                <FormInputLabel
                  validationSchema={validationSchema}
                  forceIsRequired={false}
                >
                  {"Historia jazd"}
                </FormInputLabel>

                <DataTable
                  value={historyData}
                  loading={ridesHistoryQuery.isFetching}
                  resizableColumns={true}
                  scrollable={false}
                  rowClassName={(x) => x.color}
                  style={{ width: "100%" }}
                >
                  <Column
                    field="date"
                    header="Data"
                    sortable
                    bodyClassName={(x) => "px-1 mx-1"}
                    body={(x) => x.date?.toLocaleDateString()}
                  />
                  <Column
                    field="rider"
                    header="Jezdz."
                    bodyClassName={(x) => "px-1 mx-1"}
                    sortable
                  />
                  <Column
                    field="user"
                    header="Inst."
                    bodyClassName={(x) => "px-1 mx-1"}
                    sortable
                  />
                  <Column
                    field="currentCredit"
                    header="K."
                    bodyClassName={(x) => "px-1 mx-1"}
                  />
                </DataTable>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
