import { useMutation, useQuery } from 'react-query';
import { SizeType } from './models/size-type.model';
import { sizeTypesService } from '../services/size-types.service';

export function useSizeTypesQuery() {
    return useQuery<SizeType[]>(["size-types"], () =>
        sizeTypesService.getAllActive());
}

export function useActiveSizeTypesAsSelectOptionQuery() {
    return useQuery(
        ["size-types", "size-types-options"],
        () => sizeTypesService.getAllActiveAsOptions()
    )
}

export function useAddSizeTypeMutation() {
    return useMutation((request: SizeType) => sizeTypesService.add(request));
}

export function useEditSizeTypeMutation() {
    return useMutation((request: SizeType) => sizeTypesService.edit(request));
}

export function useDeleteSizeTypeMutation() {
    return useMutation((id: number) => sizeTypesService.delete(id));
}