import { Ride } from "../../queries/models/ride.model";
import { useMemo } from "react";

export interface PlannedRideProps {
  ride: Ride;
  shortNames: boolean;
  showInstructor: boolean;
  onSelected: (ride: Ride) => void;
}

export function PlannedRide({
  ride,
  onSelected,
  shortNames,
  showInstructor,
}: PlannedRideProps) {
  const text = useMemo(() => {
    if (shortNames) {
      let result1 = "";
      if (ride.rideType?.name) {
        result1 += ride.rideType?.name;
      }
      if (ride.rider?.name) {
        result1 += "-" + ride.rider?.shortName;
      }
      if (ride.horse?.name) {
        result1 += "-" + ride.horse?.shortName;
      }
      if (ride.user?.name && showInstructor) {
        result1 += "-" + ride.user?.shortName;
      }
      return result1;
    } else {
      let result2 = "";
      if (ride.rideType?.name) {
        result2 += ride.rideType?.name;
      }
      if (ride.rider?.name) {
        result2 += "-" + ride.rider?.name;
      }
      if (ride.horse?.name) {
        result2 += "-" + ride.horse?.name;
      }
      if (ride.user?.name && showInstructor) {
        result2 += "-" + ride.user?.username;
      }
      return result2;
    }
  }, [ride, shortNames, showInstructor]);

  const colorClass = useMemo(() => {
    if (ride.rider?.useCredits && ride.rider?.credits < 1) {
      return "bg-red-200";
    } else if (ride.isPaid) {
      return "bg-green-200";
    } else {
      return "surface-200";
    }
  }, [ride]);

  const textColorClass = useMemo(() => {
    if (
      ride.isHorseExhausted ||
      ride.horseId === undefined ||
      ride.horseId === null
    ) {
      return "text-blue-600";
    } else if (ride.hasIssue || ride.hasPreviousHorseIssues) {
      return "text-red-600";
    } else {
      return "text-black";
    }
  }, [ride]);
  return (
    <div
      onClick={() => onSelected(ride)}
      className={"shadow-1 m-1 p-2 " + colorClass + " " + textColorClass}
    >
      {text}
    </div>
  );
}
